<template>
  <div>
    <vue-pdf-embed :source="$router.currentRoute.params.pdfUrl" />
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    VuePdfEmbed,
  },
}
</script>

<style>

</style>
